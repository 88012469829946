import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getAuth,
  signInWithCustomToken,
} from 'firebase/auth';
import Api from '../util/api';
// import app from '../_app/firebase';
import EmailLogInForm from './components/EmailLogInForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import Loading from '../shared/components/custom/Loading';
import t, { partial } from '../util/translation/translation';
import NOLogo from '../../assets/neveroffside-logo.svg';

const Tabs = {
  LOGIN: 'login',
  FORGOT: 'forgot',
};
const RenderTabs = ({ tab, sendResetEmail, emailLogin, loading, history, setTab }) => {
  switch (tab) {
    case Tabs.FORGOT:
      return (
        <ResetPasswordForm
          onSubmit={sendResetEmail}
          goBack={() => setTab('login')}
        />
      );
    case Tabs.LOGIN:
      return (
        <EmailLogInForm
          goToForgot={() => setTab('forgot')}
          goToPassword={() => history.push('/log_in')}
          onSubmit={emailLogin}
          loading={loading}
        />
      );
    default:
      return null;
  }
};
const EmailLoginPage = () => {
  const [tab, setTab] = useState('login');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const p = partial('LoginPage');
  const s = partial('shared');


  function handleError(error) {
    switch (error) {
      case 'auth/invalid-email':
        return toast.error(p('userNotFound'));
      case 'auth/invalid-verification-code':
        return toast.error(p('pleaseEnterCorrectCode'));
      case 'auth/too-many-requests':
        return toast.error(p('tooManyRequests'));
      case 'auth/wrong-password':
        return toast.error(t('LoginPage.pleaseEnterCorrectPassword'));
      case 'INVALID_PASSWORD': return toast.error(t('LoginPage.pleaseEnterCorrectPassword'));
      default: return toast.error(s('somethingWentWrong'));
    }
  }

  async function emailLogin({ email, password }) {
    try {
      setLoading(true);
      const lowerCaseEmail = email?.toLowerCase();
      const trimmedEmail = lowerCaseEmail?.trim();
      try {
        await Api.login.doesUserExist({ email: trimmedEmail });
      } catch {
        throw Object.assign(new Error(), { code: 'auth/invalid-email' });
      }
      const { idToken } = await Api.authorization.signInWithEmailPass(
        trimmedEmail,
        password,
      );
      const { token: customToken } = await Api.authorization.getCustomToken(idToken);
      const auth = getAuth();
      await signInWithCustomToken(auth, customToken);
      setLoading(false);
      toast.success(t('LoginPage.successfullLogin'));
    } catch (err) {
      handleError(err?.message ? err?.message : err?.code);
      setLoading(false);
    }
  }
  async function sendResetEmail({ email }) {
    setLoading(true);
    try {
      try {
        await Api.login.doesUserExist({ email });
      } catch {
        throw Object.assign(new Error(), { code: 'auth/invalid-email' });
      }
      await Api.authorization.sendPasswordResetEmail(email);
      setLoading(false);
      setTab('login');
      toast.success(p('resetEmailSent'));
    } catch (err) {
      handleError(err?.message ? { code: err?.message } : err);
      setLoading(false);
    }
  }

  return (
    <div className="account">
      <Loading loading={loading} />
      <div className="account__wrapper">
        <div className="account__card">
          <img src={NOLogo} alt="Företagslogo" className="my-30" />
          <hr />
          <h3 className="bold-text">{tab === Tabs.LOGIN ? p('welcomeToThePartnerPortal') : p('forgotPassword')}</h3>
          <RenderTabs tab={tab} sendResetEmail={sendResetEmail} emailLogin={emailLogin} loading={loading} history={history} setTab={setTab}/>
        </div>
      </div>
    </div>
  );
};

export default EmailLoginPage;
