import React, { useState, useEffect, useContext, useCallback, memo, useMemo } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import renderField from '../../shared/components/custom/Field';
import renderTextAreaField from '../../shared/components/custom/TextArea';
import renderRadioButtonField from '../../shared/components/form/RadioButton';
import renderDropZoneField from '../../shared/components/form/DropZoneMS';
import renderSelectField from '../../shared/components/form/Select';
import Api from '../../util/api';
import offerValidations from '../validation';
import { OfferContext } from '../context';
import useConfig from '../../shared/hooks/useConfig';
import { partial } from '../../util/translation/translation';
import OfferTypes from '../types';
import TooltipWithIcon from '../../shared/components/custom/TooltipWithIcon';
import AlertModal, { AlertModalType } from '../../shared/components/custom/AlertModal';

const OfferForm = (props) => {
    const s = partial('shared');
    const o = partial('OfferForm');
    const oc = partial('OfferCard');
    const { handleSubmit, initialize, employees, clubsData } = props;
    const { fetchSingleOffer, fetchLevels, fetchTiers } = Api.offers;
    const { TargetAudience } = OfferTypes;
    const [id] = useConfig();
    const [priceType, setPriceType] = useState('');
    const [selectedLevels, setSelectedLevels] = useState([]);
    const [selectedTiers, setSelectedTiers] = useState([]);
    const params = useParams();
    const history = useHistory();
    const { categories, activeOffer, setActiveOffer } = useContext(OfferContext);
    const [targetAudience, setTargetAudience] = useState(TargetAudience.partner);
    const clubs = useMemo(() => {
      return clubsData.clubs.map((club) => ({ label: club.name, value: club.clubId }));
    }, [clubsData]);

    const [selectedClub, setSelectedClub] = useState(null);
    const [levels, setLevels] = useState([]);
    const [tiers, setTiers] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [showValidationErrorModal, setShowValidationErrorModal] = useState(false);
    const [validationErrorMessage, setShowValidationErrorMessage] = useState('');
    const [promoCodeType, setPromoCodeType] = useState('none');
    const [isActivationLimited, setIsActivationLimited] = useState('unlimited');
    const employeesToBeDisplayed = useMemo(() => employees.filter((item) => item.value.clubId === selectedClub), [employees, selectedClub]);
    const getSingleOffer = useCallback(async (offerId) => {
      const singleOffer = await fetchSingleOffer(offerId, id);
      return singleOffer;
    }, [fetchSingleOffer, id]);
    const getTiers = useCallback(async () => {
        if (!selectedClub) return;
        const results = await fetchTiers(selectedClub);
        if (results.length === 0) {
          setTiers([{ label: o('all_users'), value: null, name: 'All users' }]);
        } else {
          setTiers([{ label: o('all_users'), value: null, name: 'All users' }, { label: o('all_tiers'), value: 'all', name: 'All tiers' }, ...results.map(res => { res.name = res.label; return res; })]);
        }
    }, [selectedClub, fetchTiers]);
    const getLevels = useCallback(async () => {
        if (!selectedClub) return;
        const results = await fetchLevels(selectedClub);
        if (results.length === 0) {
          setLevels([{ label: o('all_levels'), value: null, name: 'All levels' }]);
        } else {
          setLevels([{ label: o('all_levels'), value: null, name: 'All levels' }, ...results.map(res => { res.name = res.label; return res; })]);
        }
    }, [fetchLevels, selectedClub]);

    const fromatCategory = (data) => {
      if (data.includes('&')) {
        return data.split('&').map(word => word.trim().toLowerCase()).join().replace(',', '_');
      }
      return data.toLowerCase().replace(' ', '_');
    };

    const fromatTAOption = (data) => {
      return data.toLowerCase().replace(' ', '_');
    };

    const tranlateName = (name) => {
      try {
        return o(fromatTAOption(name));
      } catch (err) {
        return name;
      }
    };


    useEffect(() => {
      getTiers();
    }, [getTiers]);

    useEffect(() => {
      getLevels();
    }, [getLevels]);
    useEffect(() => {
      if (clubs.length === 1) {
        props.change('offers_form', 'club', clubs[0].value);
        setSelectedClub(clubs[0].value);
      }
      if (activeOffer && activeOffer.clubIds) {
        props.change('offers_form', 'club', activeOffer.clubIds[0]);
        setSelectedClub(activeOffer.clubIds[0]);
      }
    }, [clubs, activeOffer]);

    useEffect(() => {
      if (params.offerID) {
        getSingleOffer(params.offerID).then(offerResponse => {
          const offer = offerResponse;
          setActiveOffer(offer);
          if (offer.price === 0) {
            setPriceType('3');
          } else if (offer.discountedPrice) {
            setPriceType('1');
          } else {
            setPriceType('2');
          }
          setTargetAudience(offer.targetAudience);
          setPromoCodeType(offer.promoType ?? 'none');
          initialize({
            title: offer.title,
            subtitle: offer.subtitle,
            description: offer.description,
            category: { label: oc(fromatCategory(offer.category)), value: offer.category },
            // eslint-disable-next-line no-nested-ternary
            price: offer.price === 0 ? '3' : (offer.discountedPrice ? '1' : '2'),
            ordinaryPrice: offer.price,
            discountedPrice: offer.discountedPrice,
            discountedPercentage: offer.discountedPercentage,
            startDate: moment(offer.startDate).format('YYYY-MM-DDTHH:mm'),
            endDate: moment(offer.endDate).format('YYYY-MM-DDTHH:mm'),
            level: offer.levels?.length > 0 ? offer.levels.map(level => ({ label: tranlateName(level.name), value: level.levelId, name: level.name })) : [],
            tier: offer.tiers?.length > 0 ? offer.tiers.map(tier => ({ label: tranlateName(tier.name), value: tier.tierId, name: tier.name })) : [],
            contactPerson: employees.find((item) => item.value.id === offer.companyContactPersonId),
            offerType: offer.companyId ? '1' : '2',
            imageUrl: offer?.imageUrl ? { preview: offer?.imageUrl } : null,
            targetAudience: offer?.targetAudience,
            isVisible: offer?.isVisible,
            promoType: offer?.promoType,
            promoCode: offer?.promoCode || null,
            activationLimit: offer?.activationLimit || null,
          });
        });
      } else {
        setActiveOffer(null);
      }
    }, [params, getSingleOffer, setActiveOffer, employees]);

    useEffect(() => {
      props.setCurrentOffer(activeOffer);
    }, [activeOffer]);

    useEffect(() => {
      let currentSelectedLevels = [...selectedLevels];
      if (currentSelectedLevels.length > 1) {
        const index = currentSelectedLevels.findIndex(level => level.value === null);
        if (index !== -1 && index === 0) {
          currentSelectedLevels.splice(index, 1);
        }
        if (index !== -1 && index !== 0) {
          currentSelectedLevels = currentSelectedLevels.filter(level => level.value === null);
        }
        props.change('offers_form', 'level', currentSelectedLevels);
      }
    }, [selectedLevels]);

    useEffect(() => {
      let currentSelectedTiers = [...selectedTiers];
      if (currentSelectedTiers.length > 1) {
        const allUsersIndex = currentSelectedTiers.findIndex(tier => tier.value === null);
        if (allUsersIndex !== -1 && allUsersIndex === 0) {
            currentSelectedTiers.splice(allUsersIndex, 1);
          }
        if (allUsersIndex !== -1 && allUsersIndex !== 0) {
          currentSelectedTiers = currentSelectedTiers.filter(tier => tier.value === null);
        }
        const allTiersIndex = currentSelectedTiers.findIndex(tier => tier.value === 'all');
        if (allTiersIndex !== -1 && allTiersIndex === 0) {
          currentSelectedTiers.splice(allTiersIndex, 1);
        }
        if (allTiersIndex !== -1 && allTiersIndex !== 0) {
          currentSelectedTiers = currentSelectedTiers.filter(tier => tier.value === 'all');
        }
        if (allUsersIndex !== -1 && allTiersIndex !== -1) {
          currentSelectedTiers.splice(allUsersIndex, 1);
          currentSelectedTiers = tiers.filter(tiler => tiler.value === 'all');
        }
        props.change('offers_form', 'tier', currentSelectedTiers);
      }
    }, [selectedTiers, tiers]);

    useEffect(() => {
      if (priceType === '3') {
        props.change('offers_form', 'ordinaryPrice', null);
        props.change('offers_form', 'discountedPrice', null);
        props.change('offers_form', 'discountedPercentage', null);
      } else if (priceType === '2') {
        props.change('offers_form', 'discountedPrice', null);
      } else {
        props.change('offers_form', 'discountedPercentage', null);
      }
    }, [priceType]);

    useMemo(() => {
      setValidationErrors(props.errors.offers_form.syncErrors);
    }, [props.errors.offers_form.syncErrors]);

    const renderPriceType = (
      <div className="flex">
        <Col>
          <div className="form__form-group">
            <span className="form-header">{o('ordinaryPrice')}</span>
            <Field
              name="ordinaryPrice"
              placeholder={o('ordinaryPrice')}
              component={renderField}
            />
          </div>
        </Col>
        {priceType === '1' &&
        <Col>
          <div className="form__form-group">
            <span className="form-header">{o('discountedPrice')}</span>
            <Field
              name="discountedPrice"
              placeholder={o('discountedPrice')}
              component={renderField}
            />
          </div>
        </Col>}
        {priceType === '2' &&
        <Col>
          <div className="form__form-group">
            <span className="form-header">{o('discountedPercentage')}</span>
            <Field
              name="discountedPercentage"
              placeholder={o('discountedPercentage')}
              component={renderField}
            />
          </div>
        </Col>}
      </div>
    );

    const handleValidationModal = () => {
      if (!validationErrors) return;
      const errors = Object.values(validationErrors);
      if (errors.length > 0) {
        setShowValidationErrorModal(true);
        setShowValidationErrorMessage(errors[0]);
      }
    };
    return (
      <form onSubmit={handleSubmit} className="form">
        <AlertModal visible={showValidationErrorModal} modalType={AlertModalType.ERROR} handleModal={() => setShowValidationErrorModal(false)} message={validationErrorMessage} />
        <Container className="padding-0">
          <Row>
            <div className="col-12 col-lg-7">
              {clubs.length > 1 &&
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{o('selectClub')}</span>
                  <Field
                    disabled={!!activeOffer}
                    name="club"
                    placeholder={`${o('selectClub')}`}
                    options={clubs}
                    component={renderSelectField}
                    onChange={(e) => {
                      setSelectedClub(e.value);
                      props.change('offers_form', 'contactPerson', null);
                    }}
                  />
                </div>
              </Col>}
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{o('NameOnTheOffer')}*</span>
                  <Field
                    name="title"
                    placeholder={o('descriptiveNameOfOffer')}
                    component={renderField}
                  />
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{o('subtitle')}</span>
                  <Field
                    name="subtitle"
                    placeholder={o('subtitleOfOffer')}
                    component={renderField}
                  />
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{o('offerDescription')}*</span>
                  <Field
                    name="description"
                    placeholder={o('descriptionText')}
                    component={renderTextAreaField}
                  />
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{o('category')}*</span>
                  <Field
                    name="category"
                    placeholder={o('chooseCategoryForTheOffer')}
                    options={categories.filter(category => category.key !== 'all_categories')}
                    component={renderSelectField}
                  />
                </div>
              </Col>
              <Col>
                <div className="flex">
                  <span className="form mr-1">{o('priceinfo')}
                    {priceType !== '3' && '*'}
                  </span>
                  {priceType !== '3' && <TooltipWithIcon text={o('priceinfoTooltip')} id="priceInfoTooltip" />}
                </div>
                <div className="flex flex-column flex-xl-row">
                  <div className="form__form-group margin-0">
                    <div className="form__form-group-field">
                      <Field
                        name="price"
                        label={o('priceDiscount')}
                        component={renderRadioButtonField}
                        radioValue="1"
                        defaultChecked
                        onChange={(e) => setPriceType(e)}
                      />
                    </div>
                  </div>
                  <div className="form__form-group margin-0">
                    <div className="form__form-group-field">
                      <Field
                        name="price"
                        component={renderRadioButtonField}
                        label={o('percentageDiscount')}
                        radioValue="2"
                        onChange={(e) => setPriceType(e)}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="price"
                        component={renderRadioButtonField}
                        label={o('showNoPrice')}
                        radioValue="3"
                        onChange={(e) => setPriceType(e)}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              {priceType !== '3' && renderPriceType}
              <div className="flex flex-column flex-xl-row">
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{s('startDate')}</span>
                    <Field
                      name="startDate"
                      placeholder={s('startDate')}
                      type="datetime-local"
                      component={renderField}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{s('endDate')}</span>
                    <Field
                      name="endDate"
                      placeholder={s('endDate')}
                      type="datetime-local"
                      component={renderField}
                    />
                  </div>
                </Col>
              </div>
              <Col>
                <div className="flex">
                  <span className="form mr-1">{o('targetAudience')}</span>
                </div>
                <div className="flex">
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="targetAudience"
                        label={o('partner')}
                        component={renderRadioButtonField}
                        radioValue={TargetAudience.partner}
                        defaultChecked
                        onChange={(e) => setTargetAudience(e)}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="targetAudience"
                        component={renderRadioButtonField}
                        label={o('private')}
                        radioValue={TargetAudience.private}
                        onChange={(e) => setTargetAudience(e)}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="targetAudience"
                        component={renderRadioButtonField}
                        label={s('all')}
                        radioValue={TargetAudience.both}
                        onChange={(e) => setTargetAudience(e)}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              {(targetAudience === TargetAudience.partner) &&
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{o('partnerlevelList')}</span>
                  <Field
                    name="level"
                    placeholder={o('partnerlevelList')}
                    options={levels}
                    onChange={(e) => setSelectedLevels(e)}
                    component={renderSelectField}
                    multiple
                  />
                </div>
              </Col>}
              {(targetAudience === TargetAudience.private) &&
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{o('tierList')}</span>
                  <Field
                    name="tier"
                    placeholder={o('tierList')}
                    options={tiers}
                    onChange={(e) => setSelectedTiers(e)}
                    component={renderSelectField}
                    multiple
                  />
                </div>
              </Col>}
              {(targetAudience !== TargetAudience.both) &&
              <Col>
                <div className="flex">
                  <span className="form">{o('visibility')}</span>
                  <TooltipWithIcon text={o('visibilityInfoTooltip')} id="visibilityInfoTooltip" />
                </div>
                <div className="flex">
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="isVisible"
                        label={s('all')}
                        component={renderRadioButtonField}
                        radioValue
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="isVisible"
                        component={renderRadioButtonField}
                        label={s('targetAudience')}
                        defaultChecked
                        radioValue={false}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              }
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{o('responsibleContactPerson')}</span>
                  <Field
                    name="contactPerson"
                    placeholder={o('choosePerson')}
                    options={employeesToBeDisplayed}
                    component={renderSelectField}
                    disabled={!selectedClub}
                  />
                </div>
              </Col>
            </div>
            <div className="col-12 col-lg-5">
              <Col>
                <Field
                  name="imageUrl"
                  className="dropzone"
                  component={renderDropZoneField}
                  crop="BANNER_CROP"
                />
                <span className="form-header">{s('note')}: {s('recommendedImageSize')} 16:9 (1035 x 570 px)</span>
              </Col>
              <Col>
                <div className="flex flex-column">
                  <div className="form__form-group margin-0">
                    <div className="form__form-group-field">
                      <Field
                        name="promoType"
                        label={o('none')}
                        component={renderRadioButtonField}
                        radioValue="none"
                        onChange={(e) => setPromoCodeType(e)}
                        defaultChecked
                      />
                    </div>
                  </div>
                  <div className="form__form-group margin-0">
                    <div className="form__form-group-field">
                      <Field
                        name="promoType"
                        label={o('promoCode')}
                        component={renderRadioButtonField}
                        radioValue="code"
                        onChange={(e) => setPromoCodeType(e)}
                        defaultChecked
                      />
                    </div>
                  </div>
                  <div className="form__form-group margin-0">
                    <div className="form__form-group-field">
                      <Field
                        name="promoType"
                        label={o('qrCode')}
                        component={renderRadioButtonField}
                        radioValue="qrstring"
                        onChange={(e) => setPromoCodeType(e)}
                        defaultChecked
                      />
                    </div>
                  </div>
                  <div className="form__form-group margin-0">
                    <div className="form__form-group-field">
                      <Field
                        name="promoType"
                        label={s('link')}
                        component={renderRadioButtonField}
                        radioValue="link"
                        onChange={(e) => setPromoCodeType(e)}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              </Col>
              {promoCodeType !== 'none' &&
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{o(promoCodeType)}</span>
                    <Field
                      name="promoCode"
                      component={renderField}
                    />
                  </div>
                </Col>
              }
              <Col>
                {promoCodeType !== 'none' && promoCodeType !== 'link' ? (
                  <>
                    <div className="flex marginTop-20">
                      <span className="form-header">{o('activationLimit')}</span>
                    </div>
                    <div className="flex flex-column flex-xl-row">
                      <div className="form__form-group margin-0">
                        <div className="form__form-group-field">
                          <Field
                            name="isActivationLimited"
                            label={o('unlimited')}
                            component={renderRadioButtonField}
                            radioValue="unlimited"
                            onChange={(e) => setIsActivationLimited(e)}
                            defaultChecked
                          />
                        </div>
                      </div>
                      <div className="form__form-group margin-0">
                        <div className="form__form-group-field">
                          <Field
                            name="isActivationLimited"
                            label={o('limited')}
                            component={renderRadioButtonField}
                            radioValue="limited"
                            onChange={(e) => setIsActivationLimited(e)}
                          />
                        </div>
                      </div>
                    </div>
                    {isActivationLimited === 'limited' ? (
                      <Field
                        name="activationLimit"
                        placeholder={o('limit')}
                        component={renderField}
                        type="number"
                      />
                    ) : null}
                  </>
                ) : null}
              </Col>
            </div>
          </Row>
          <Col>
            <Button onClick={handleValidationModal} type="submit" color="primary">{params.offerID ? s('update') : s('save')}</Button>
            <Button className="color-unset" onClick={() => history.replace('/offers')}>
              {s('close')}
            </Button>
          </Col>
        </Container>
      </form>
    );
};

const mapStateToProps = (state) => ({
  errors: state.form,
  clubsData: state.clubs,
});
const mapDispatchToProps = { change };

export default reduxForm({
  form: 'offers_form',
  validate: offerValidations,
})(connect(mapStateToProps, mapDispatchToProps)(memo(OfferForm)));
